/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
import { client, clientCall, client1, getJWToken } from "./client";
import jwtDecode from "jwt-decode";
import { appConfig } from "../config/app-config";
import { CancelToken } from "axios";
import { ProductPermissions } from "../constants/constants";
import { CustSurveyType, User } from "../types/types";
const QAToken =
  "eyJraWQiOiI3M1BNSjFNQm1ObkVUU3hPZFNqYWVibVRFK0tjZTRvdVwva1ZDT2VNTGgwbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIydm42dGV0MmhxYmpmbW00ZzdrdDFlNWw4NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicmFjXC9yZWFkIiwiYXV0aF90aW1lIjoxNjM1NTQ1NTY3LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV84TWJPeUVZRzYiLCJleHAiOjE2MzU1NDkxNjcsImlhdCI6MTYzNTU0NTU2NywidmVyc2lvbiI6MiwianRpIjoiMWI4NjJmZDctZGE0Zi00ZTMwLWJmM2QtMGUwOGQwNDRmMmYwIiwiY2xpZW50X2lkIjoiMnZuNnRldDJocWJqZm1tNGc3a3QxZTVsODcifQ.qOF9aMMN_8nCia6JbsZmUy6wD96wsWPZmZVULqu3bsa7RDctmVH3gbTANOToA-U3xqNGSFL9rMrHU8YmAuEgI9NXtMxcZzOXJrVvV_Zlpq6Kb6FgIRtDOFzOGvuW41aOAzOxL4H7BjLOLzbbIkfWINBUME68NhXyP8ZMDrjP0pw75sqw2peIaMPILQqEJUARNiFaphrMRz267SUo2LIkLFxsq20rE_tgCTkFZ8n_zgUZ5ejJnM92VQkd-2hmp8SCdqalEI4r1Q1vva3mkR8kaLqN2Ef0b_XMj4bMXnbl1DNKPdyi48GknOFD81AonGXqW5hJFxe1LfTOB4H0kcryeA";
const agreementBaseUrl = appConfig.apiUrls.agreement;
const TwoFactorBaseURL = appConfig.apiUrls.storemgmt;
const getCurrentUserBaseURL = appConfig.apiUrls.menu;
const customerBaseUrl = appConfig.apiUrls.customers;
const inventoryBaseUrl = appConfig.apiUrls.inventory;

export const getSalesLead = async (paramOnlineTransactionsalesLead: any) => {
  try {
    return await clientCall(
      `leads`,
      { method: "POST", body: paramOnlineTransactionsalesLead },
      appConfig.apiUrls.micro,

      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const GetAgreementCustomer = async (Pathparams: any) => {
  return await clientCall(
    `agreement/customers/${Pathparams}?source=ENT`,
    { method: "GET" },
    agreementBaseUrl,
    QAToken,
    true
  );
};

export const getReference = async (referenceKey: any[],
  cacheKey: string) : Promise<any[]> => {
  return await clientCall(
    `references`,
    { method: "POST", body: { references: referenceKey, cacheKey }, },
    appConfig.apiUrls.am,
    QAToken,
    true
  );
};



export const logActivity = async (payload: any) => {
  return await clientCall(
    '/customer/logactivity',
    { method: "POST", body: { ...payload } },
    appConfig.apiUrls.am,
    QAToken,
    true
  );
};
export const getStoreActionsItem = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `storeactionsitem/details/${payload.storeNum}?limit=${payload.limit}&offset=${payload.offset}`,
      { method: "GET" },
      appConfig.apiUrls.micro,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const manageStoreActionItems = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `/storeactionitems/manage`,
      { method: "POST", body: payload },
      appConfig.apiUrls.micro,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
  }
};
export const getPrintEditPricetagGrid = async (storeNumber: any) => {
  try {
    return await clientCall(
      `inventory/pricetag/list/${storeNumber}?limit=20&offset=1`,
      { method: "GET" },
      inventoryBaseUrl,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
  }
};

export const getOnlinetransaction = async (storeNumber: any) => {
  /* eslint-disable no-console */
  console.log("fe-lambda hit");
  try {
    return await clientCall(
      `online-transaction/${storeNumber}`,
      { method: "GET" },
      appConfig.apiUrls.micro,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const getSalesLeadInvenventoryLambda = async (
  paramsSalesInventory: any
) => {
  return await clientCall(
    `lead/inventory-status`,
    { method: "POST", body: paramsSalesInventory },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const logWorkedHistory = async (
  payload: CustSurveyType
) => {
  return await clientCall(
    `customer/logactivity`,
    { method: "POST", body: payload },
    appConfig.apiUrls.am,
    QAToken,
    true
  );
};


export const getCustomerForsalesLead = async (gcid: any) => {
  return await clientCall(
    `customer/${gcid}/detail`,
    { method: "GET" },
    customerBaseUrl,
    QAToken,
    true
  );
};

export const UpdateCustomerForsalesLead = async (
  paramsUpdateSalesLead: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customerId: any
) => {
  return await clientCall(
    `customer/post-no-decision`,
    { method: "POST", body: paramsUpdateSalesLead },
    customerBaseUrl,
    QAToken,
    true
  );
};

export const getCurrentUser = async (): Promise<User> =>
  await client("users/current", { method: "GET" }, appConfig.apiUrls.container, true);

export const getUserPreferences = async (productType: string) =>
  await client(
    `users/current/preferences/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const getUserPermissions = async (
  productType: string
): Promise<ProductPermissions[]> =>
  await client(
    `users/current/permissions/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const setUserPreferences = async (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  await client(
    `users/current/preferences/${productType}`,
    {
      method: "PATCH",
      body: [payload],
      cancelToken,
    },

    appConfig.apiUrls.container
  );

export const getUserStores = async () =>
  await client(
    "users/current/stores?q=permissions[:includes]=RACPAD_DAP",
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const GenerateAgreementDoc = async (Payload: any) => {
  return await clientCall(
    `agreement/document/generate`,

    { method: "POST", body: Payload },

    agreementBaseUrl,
    QAToken,
    true
  );
};
export const managePoAcimaFn = async (bodyobj: any) => {
  return await clientCall(
    `manage/${bodyobj.purchaseOrderNumber}`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.acimapo,
    QAToken,
    true
  );
};
export const GetCustomerinfo = async (agreementId: any) => {
  return await clientCall(
    `agreement/customers/${agreementId}`,
    {
      method: "GET",
    },
    agreementBaseUrl,
    QAToken,
    true
  );
};

//Authentication popup
export const getprofileaccess = async (Payload: any) => {
  return await clientCall(
    `store-access`,
    { method: "POST", body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};
export const getPinDetails = async (Payload: any) => {
  return await clientCall(
    `coworker`,
    { method: "POST", body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};

export const getsecondfactor = async (Payload: any) => {
  return await clientCall(
    `coworker-authentication`,
    { method: "POST", body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};
export const getConfigDetails = async (payload: any) => {
  try {
    return await clientCall(
      `store-details`,
      { method: "POST", body: payload },
      TwoFactorBaseURL,
      QAToken
    );
  } catch (e: any) {
    return null;
  }
};

export const GetEmployeeID = async () => {
  return await clientCall(
    `user/current`,
    { method: "GET" },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};

export const GetRole = async (payload: any) => {
  return await clientCall(
    `menu-and-stores`,
    { method: "POST", body: payload },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};
// export const getPrintEditPricetagGrid = async (storeNumber: any) => {
//   return await clientCall(
//     `inventory/pricetag/list/${storeNumber}`,
//     { method: 'GET' },
//     inventoryBaseUrl,
//     QAToken,
//     true
//   );
// };
export const getPrintDocument = async (payload: any) => {
  return await clientCall(
    `agreement/document/print`,
    { method: "POST", body: payload },
    agreementBaseUrl,
    QAToken,
    true
  );
};

export const getTextMessage = async () => {
  return await clientCall(
    `dashboard/textmsg`,
    { method: "GET" },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const GetCustomer = async (customerId: any, payload: any) => {
  return await clientCall(
    `customer/${customerId}`,
    { method: "PUT", body: payload },
    customerBaseUrl,
    QAToken,
    true
  );
};

export const GetCustomerDetail = async (gcid: any) => {
  return await clientCall(
    `customer/${gcid}/detail`,
    { method: "GET" },
    customerBaseUrl,
    QAToken,
    true
  );
};

export const getStatsInfo = async (date: string, isRacdbStoreStats: string) => {
  return await clientCall(
    `dashboard/store/stats?date=${date}&isRacdbStoreStats=${isRacdbStoreStats}`,
    { method: "GET" },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const GetStatsStatus = async (payload: any) => {
  return await clientCall(
    `store-details`,
    { method: "POST", body: payload },
    appConfig.apiUrls.storemgmt,
    QAToken,
    true
  );
};
export const getDrawerDetail = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    return await clientCall(
      `manage/reconcil`,
      { method: "POST", body: payload },
      appConfig.apiUrls.cashmgmt,
      QAToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};

export const updateTransferReceiveDetails = async (req: any) => {

  // eslint-disable-next-line no-console
  console.log('request received in user for prictagstatus', req);

  return await clientCall(

    'inventory/transfer/update',

    { method: 'POST', body: req },

    appConfig.apiUrls.inventory,

    QAToken,

    true

  );
}


export const GetFscInfoDetail = async (payload: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await clientCall(
      `fsc/info?storeNumber=${payload.storeNumber}&type=${payload.type}`,
      { method: "POST" },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetFscContest = async (payload: any) => {
  try {
    // eslint-disable-next-line no-debugger
    const TempAccessToken = "QAToken";
    let url = `fsc/info?storeNumber=${payload.storeNumber}&type=${payload.type}`;
    if (payload.operationCommunicationId) {
      url =
        url + `&operationCommunicationId=${payload.operationCommunicationId}`;
    }
    return await clientCall(
      url,
      { method: "POST" },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const GetReferenceValues = async (param: string) => {
  try {
    return await clientCall(
      `reference?referenceName=${param}`,
      { method: "GET" },
      appConfig.apiUrls.cashmgmt
    );
  } catch (e: any) {
    return null;
  }
};
export const getDapEvent = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `event/search`,
      { method: "POST", body: payload },
      appConfig.apiUrls.storemgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
  }
};
export const EventHistory = async (Payload: any) => {
  return await clientCall(
    "event/history",
    { method: "POST", body: Payload },
    appConfig.apiUrls.storemgmt,
    QAToken,
    true
  );
};
export const updateEventStatus = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `appointment/action`,
      { method: "POST", body: payload },
      appConfig.apiUrls.storemgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};

export const getTimeSlot = async (Payload: any) => {
  return await clientCall(
    "agreement/timeslots",
    { method: "POST", body: Payload },
    appConfig.apiUrls.agreement
  );
};
export const getAgreementDelivery = async (
  agreementId: any,
  appointmentId?: any
) => {
  return await clientCall(
    `agreement/delivery/info/${agreementId}?appointmentId=${appointmentId}`,
    { method: "Get" },
    appConfig.apiUrls.agreement,
    QAToken,
    true
  );
};
export const getnextpaymentamount = async (Payload: any) => {
  return await clientCall(
    `agreement/getnextpaymentamount`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.agreement,
    QAToken,
    true
  );
};
export const getAgreementInfo = async (AgreementId: any) => {
  return await clientCall(
    `agreement/${AgreementId}`,
    { method: "GET" },
    appConfig.apiUrls.agreement,
    QAToken,
    true
  );
};
export const LoadDeliveryreceipt = async (agreementId: string) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery/receipt/${agreementId}`,
    { method: "GET" },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const GetCustomerId = async (GCID: string) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `customer/${GCID}/detail`,
      { method: "GET" },
      customerBaseUrl,
      TempAccessToken
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return "Error";
  }
};
export const GenerateReceipt = async (bodyobj: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/generatereceipt`,
    { method: "POST", body: bodyobj },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const GenerateSwitchoutReceipt = async (bodyobj: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/generatereceipt`,
    { method: "POST", body: bodyobj },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const Confirmdelivery = async (updatedeliverystatus: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery/status/update`,
    { method: "PUT", body: updatedeliverystatus },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const Recieptsign = async (updatesign: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery/receipt/sign`,
    { method: "PUT", body: updatesign },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const LoadswitchoutDeliveryreceipt = async (agreementId: string) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery/receipt/${agreementId}?isSwitchOut=1`,
    { method: "GET" },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const FinishdeliveryStatus = async (complete: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery`,
    { method: 'POST', body: complete },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const CheckSign = async (agreementId: string) => {
  const TempAccessToken: any = QAToken;
  return await client1(
    `agreement/delivery-receipt/sign/check/${agreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const manageAppointment = async (Payload: any) => {
  return await clientCall(
    "event/manage",
    { method: "POST", body: Payload },
    appConfig.apiUrls.storemgmt,
    QAToken,
    true
  );
};
export const getSalesPersonOptions = async (
  StoreNumber: any
) => {
  const customerId = "12345"
  return await clientCall(
    `agreement/options/${customerId}?storeNumber=${StoreNumber}&isSwitchOut=1`,
    { method: 'GET' },
    appConfig.apiUrls.agreement,
    QAToken,
    true
  );
};
export const getStoreTask = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/${payload}`,
      { method: 'GET' },
      appConfig.apiUrls.storemgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const getStoreTaskHistory = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/history/${payload}`,
      { method: 'GET' },
      appConfig.apiUrls.storemgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const searchStoreTask = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/search`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.storemgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};

export const ActiveMailing = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('Inside the usee.tsx')
  return await clientCall(
    `customer/address/update`,
    { method: 'POST', body: payload },
    appConfig.apiUrls.customers,
    QAToken,
    true
  );
}

export const createStoreTask = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/create`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.storemgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const getmenuandstore = async (falg?: any) => {
  try {
    const jwtToken = await getJWToken() || ''
    const decodedToken: any = jwtDecode(jwtToken);
    // eslint-disable-next-line no-console
    console.log("decodedToken", decodedToken);
    const parameters: any = {
      coworkerId: decodedToken["custom:employeeId"],
    };
    if (falg == 'MENU') {
      parameters.storesRequired = false
      parameters.menuRequired = true
      parameters.menuRequest = {
        association: {
          associationCode: sessionStorage.getItem('storeNumber'),
          associationType: "STORE",
        },
      };
    }
    return await clientCall(
      "menu-and-stores",
      { method: "POST", body: parameters },
      getCurrentUserBaseURL
    );
  } catch (e: any) {
    return null;
  }
};
export const storeException = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `agreement/store/exception`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.agreement,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};

export const featureFlags = async (bodyobject: any) => {
  return await clientCall(
    `agreement/address/eventId`,
    { method: 'POST', body: bodyobject },
    appConfig.apiUrls.agreement,
    QAToken,
    true
  );
};

export const getRetentionOffers = async (Payload: any) : Promise<any>  => {
  // horrible name 
  return await getFreeTimeBalance( Payload );  
};

export const getFreeTimeBalance = async (Payload: any) => {
  return await clientCall(
    `retention/offers`,
    { method: 'POST', body: Payload },
    customerBaseUrl,
    QAToken,
    true
  );
};
export const saveSurveyForm = async (Payload: any) => {
  return await clientCall(
    `store/survey/agreement`,
    { method: "POST", body: Payload },
    TwoFactorBaseURL,
    "QAToken",
    true
  );
};

export const getSavedSurvey = async (agreementId: any) => {
  return await clientCall(
    `store/survey/getagreement/${agreementId}`,
    { method: "GET" },
    TwoFactorBaseURL,
    "QAToken",
    true
  );
};

export const GetInventoryInformation = async (inventoryId: any, type?: any) => {
  // eslint-disable-next-line no-console
  console.log('invinforeq', inventoryId);
  // eslint-disable-next-line no-console
  console.log('type', type);

  return await clientCall(
    `inventory/1?storeNum=&inventoryId=${inventoryId}`,
    { method: 'GET' },
    inventoryBaseUrl,
    QAToken,
    true
  );
};

export const RefundLeadAmount = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  /* eslint-enable no-console */
  try {
    return await client(
      `saleLead/refund`,
      { method: 'POST', body: payload },
      customerBaseUrl,
      true
    );
  } catch (e: any) {
    return null;
  }
};