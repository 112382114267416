/* eslint-disable */
import {
  RACModal,
  Typography,
  RACButton,
  makeStyles,
  RACSelect,
  TextField,
} from '@rentacenter/racstrap';

import React, { useState, useEffect } from 'react';
import { useUserStateContext } from '../../../context/user/user-contexts';
import { logActivity } from '../../../api/user';
import { ReferenceKeys } from '../../../types/types';
import { ActivityPayload } from '../../../types/types';
import {
  WORKED_HISTORY_ACTIVITY_TYPE_CODES,
  API_ERROR_MESSAGE,
  SELECT_ONE_OPTION,
  CACHED_KEYS,
  WORKED_HISTORY_CALL_RESULT_CODES,
  STORE_NUMBER_KEY,
} from '../../../constants/constants';

import { getReference } from '../../../api/user';

import { Option } from '../../../types/types';

export interface ModalProps {
  onClose: (onclose: boolean) => void;
  onSaveSuccess: () => void;
  onError: () => void;
  customerId: string;
}

export const FIELD_VISIT_NOTES_MAX_LENGTH = 256;

export interface FieldVisitModalContentProps {
  callResultOptions: Option[];
  apiError: boolean;
  callResult: string;
  note: string;
  isLoading: boolean;

  setCallResult: (result: string) => void;
  setNote: (note: string) => void;
}
const addSelectOneOption = (arrayWithOptions: any[]) => {
  if (!arrayWithOptions) {
    return [
      {
        label: SELECT_ONE_OPTION,
        value: '',
      },
    ];
  }

  return [
    {
      label: SELECT_ONE_OPTION,
      value: '',
    },
    ...arrayWithOptions,
  ];
};

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    marginBottom: '2rem',    
  },
  dialogRoot: {
    '& .MuiPaper-root': {
      width: theme.typography.pxToRem(500),
      maxWidth: theme.typography.pxToRem(500),
      minWidth: theme.typography.pxToRem(500),
      '!important': true,
    },
    '& .MuiDialog-paper': { 
      width: `${theme.typography.pxToRem(500)} !important`,  // Force exact width
      maxWidth: `${theme.typography.pxToRem(500)} !important`, 
      minWidth: `${theme.typography.pxToRem(500)} !important`,
    },    
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(500),
      width: theme.typography.pxToRem(500),
      minWidth: theme.typography.pxToRem(500),
    },
    '& .MuiDialog-paperWidthXs': {
      maxWidth: theme.typography.pxToRem(500),
      width: theme.typography.pxToRem(500),
      minWidth: theme.typography.pxToRem(500),
    },    
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),      
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiTypography-h6': {
      fontFamily: 'OpenSans-semiBold',
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  notesLabel: {
    color: '#212529',
    padding: '0px 0px 15px',
  },
  fieldVisitTextContent: {
    borderRadius: '5px',
    width: theme.typography.pxToRem(400),
  },
  callResultWrapper: {
    width: '75%',
  },
  callResultOptionsPaper: {
    maxHeight: theme.typography.pxToRem(150),
  },
  notesCharacterCount: {
    marginTop: theme.typography.pxToRem(5),
  },
}));

const FieldVisitModalContent = ({
  callResultOptions,
  apiError,
  callResult,
  note,
  isLoading,

  setCallResult,
  setNote,
}: FieldVisitModalContentProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.callResultWrapper}>
        <RACSelect
          classes={{ paper: classes.callResultOptionsPaper }}
          inputLabel="Field Visit Result"
          isDisabled={isLoading}
          defaultValue={callResult}
          loading={isLoading && !callResult.length}
          options={callResultOptions}
          onChange={(e: React.ChangeEvent<{ value: any }>) =>
            setCallResult(e.target.value)
          }
          {...(apiError && {
            errorMessage: API_ERROR_MESSAGE,
          })}
        />
      </div>
      <Typography variant="h6" className={classes.notesLabel}>
        Notes
      </Typography>
      <TextField
        className={classes.fieldVisitTextContent}
        multiline
        autoFocus
        variant="outlined"
        maxRows={3}
        minRows={3}
        value={note}
        onChange={(e) => {
          if (note.length >= FIELD_VISIT_NOTES_MAX_LENGTH) {
            const trimmedNote = e.target.value?.substring(
              0,
              FIELD_VISIT_NOTES_MAX_LENGTH
            );
            setNote(trimmedNote);
          } else {
            setNote(e.target.value);
          }
        }}
      />
      <span className={classes.notesCharacterCount}>
        ({FIELD_VISIT_NOTES_MAX_LENGTH}/{note.length})
      </span>
    </>
  );
};


export const FieldVisitModal = ({
  onClose,
  onSaveSuccess,
  onError,
  customerId,
}: ModalProps) => {
  // const classes = useStyles();
  const other = useStyles();

  const [note, setNote] = useState('');
  const { user } = useUserStateContext();
  const [pending, setPending] = useState(false);
  const [fieldVisitResultOptions, setFieldVisitResultOptions] = useState<
    Option[]
  >([]);
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [fieldVisitResult, setFieldVisitResult] = useState('');

  const handleSave = () => {
    const now = new Date().toISOString();
    const custId = customerId;

    const payload: ActivityPayload = {
      storeNumber: window.sessionStorage.getItem(STORE_NUMBER_KEY) || '',
      notes: note,
      activityDate: now,
      callResultRefCode: WORKED_HISTORY_CALL_RESULT_CODES.FIELD_VISIT,
      acctActivityRefCode: WORKED_HISTORY_ACTIVITY_TYPE_CODES.CALL_CUSTOMER,
      //zzz, add fieldvisit result
      fieldVisitResultRefCode: fieldVisitResult,
      customerId: Number(custId),
      coWorkerId: user?.employeeId || ' ',
      callTime: now,
    };
    setPending(true);
    logActivity(payload)
      .then(() => onSaveSuccess())
      .catch(() => onError())
      .finally(() => setPending(false));
  };

  
function randomize(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const mapReferenceResponse = (references: any[]) => {
  if (!references || !references.length) return references;
  return references.map((reference) => ({
    label: reference.description,
    value: reference.referenceCode,
    ...reference,
  }));
};

// The `pipe` function takes an array of functions and applies them sequentially.
const pipe = (functions: any[]) => {
  
  // Returns a function that takes an `initialValue` and processes it through the pipeline.
  return (initialValue: any) => {

    // Log the initial value before processing.
    // console.log("Initial Value:", initialValue);

    // Uses `reduce` to apply each function in the `functions` array.
    return functions.reduce((currentValue, func) => func(currentValue), initialValue);
  };
};

const processResponse = ( input: any ) => {
  // console.log(input);
  // console.log( JSON.stringify(input?.[0]?.referenceDetails) );

  return pipe( [randomize, mapReferenceResponse, addSelectOneOption, setFieldVisitResultOptions] )(input?.[0]?.referenceDetails);
};
 

  useEffect(() => {
    setIsLoading(true);
    getReference(
      [ReferenceKeys.FIELD_VISIT_RESULT],
      CACHED_KEYS.FIELDVISITRESULT_CACHED_KEY
    )
      .then( ( response: any) => { 
        if ( response ) {
          processResponse(response?.data?.references);          
         } else {
          setApiError(true);
         } } )
    .catch((ee) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(ee));
        setApiError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <RACModal
      isOpen
      classes={{
        dialog: other.dialogRoot,
      }}
      maxWidth="xs"      
      title="Field Visit"
      content={
        <FieldVisitModalContent
          callResultOptions={fieldVisitResultOptions}
          apiError={apiError}
          isLoading={isLoading}
          callResult={fieldVisitResult}
          note={note}
          setCallResult={setFieldVisitResult}
          setNote={setNote}
        />
      }
      onClose={() => onClose(false)}
      buttons={
        <>
          <RACButton
            variant="outlined"
            color="secondary"
            onClick={() => onClose(false)}
            disabled={pending}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!fieldVisitResult.length || isLoading}
            loading={pending}
          >
            Save
          </RACButton>
        </>
      }
    />
  );
};
