/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
//PS_CS-1
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Typography,
  RACButton,
  RACRadio,
  RACModalCard,
  RACTextbox,
  RACSelect,
  CircularProgress,
  Card,
} from '@rentacenter/racstrap';
import SecondFactor from '../../src/components/SecondFactorAuthentication/TwoFactorPopup';
import { agreementGlobalStyles } from '../components/agreementGlobalStyles';
import {
  GenerateAgreementDoc,
  GetCustomer,
  GetEmployeeID,
  getPrintDocument,
  GetRole,
  storeException
} from '../api/user';
import { ReactComponent as Alerticon } from '../assets/images/no-records-found.svg';
import { ReactComponent as PDFImage } from '../assets/images/pdf-icon.svg';
import { ContainerContext } from '../app/App';
import { CustomPropInterface } from '../../src/index';
import { level4customerSignModuleName } from '../constants/constants';

interface CustomerSignatureProps {
  customerSignUsingField:
    | 'agreementDetails'
    | 'deliveryMethod'
    | 'dashBoard'
    | 'club';
  customerSignUsingModule:
    | 'switchOut'
    | 'rental'
    | 'customerOrder'
    | 'reInstate'
    | 'payment'
    | 'split'
    | 'loaner';
  identifier: number;
  customersArr: CustomersArr[];
  manualSignOptions: ManualSignOptions[];
  documentTypeArr: string[] | [];
  customerSignatureCompletedFn: any;
  customerSignatureCanceledFn: any;
  emailcompletedFn?: any;
  documentGenerateCompleted: boolean;
  splitAgrDocPayloadArray?: any;
  setSplitAgrDocPayloadArray?: any;
}
interface ManualSignOptions {
  value: string;
  label: string;
}
interface CustomersArr {
  customerId: string;
  customerName: string;
  phoneNumber: string;
  emailId: string;
  firstName?: string;
  lastName?: string;
}
export default function CustomerSignature(props: CustomerSignatureProps) {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  //PS_CS-2
  const {
    customerSignUsingField,
    customerSignUsingModule,
    identifier,
    customersArr,
    manualSignOptions,
    documentTypeArr,
    customerSignatureCompletedFn,
    customerSignatureCanceledFn,
    emailcompletedFn,
    documentGenerateCompleted,
    splitAgrDocPayloadArray,
    setSplitAgrDocPayloadArray,
  } = props;
  const classes = agreementGlobalStyles();
  const validPhoneError = 'Please enter valid Phone Number';
  const emptyPhoneError = 'Please enter Phone Number';
  const bothCustomer = 'Both customer & co-customer';
  const validEmailError = 'Please enter valid Email';
  const emptyEmailError = 'Please enter Email';
  const customerSignObj = {
    presentToSign: bothCustomer,
    signPlaceChange: '',
    customerName: '',
    coCustomerName: '',
    customerPhoneNumber: '',
    coCustomerPhoneNumber: '',
    customerEmail: '',
    coCustomerEmail: '',
    digitalSignSelected: true,
    digiSignChecked: true,
    manualSignChecked: false,
    inStoreChecked: true,
    emailChecked: false,
    emailDisabled: false,
    bothcustomerselected: false,
  };
  const sendAgreementObj = {
    SendAgreementEmail: true,
    SendAgreementPrint: false,
    SendAgreementEmailTxt: '',
  };

  let [level4SecondFactorEnabled, setLevel4SecondFactorEnabled] = useState(false)
  let [level4SecondFactorCompleted, setLevel4SecondFactorCompleted] = useState(false)
  let [level4SecondFactorRequired, setLevel4SecondFactorRequired] = useState<any>(false)
  const [printDocErrorOpen, setprintDocErrorOpen] = useState(false);
  const [customerSign, setcustomerSign]: any = useState(customerSignObj);
  const [customerNumEnterted, setcustomerNumEnterted] = useState(true);
  const [coCustomerNumEnterted, setcoCustomerNumEnterted] = useState(true);
  const [customerNumValid, setcustomerNumValid] = useState(true);
  const [coCustomerNumValid, setcoCustomerNumValid] = useState(true);
  const [customerEmailValid, setcustomerEmailValid] = useState(true);
  const [coCustomerEmailValid, setcoCustomerEmailValid] = useState(true);
  const [customerEmailEntered, setcustomerEmailEntered] = useState(true);
  const [coCustomerEmailEntered, setcoCustomerEmailEntered] = useState(true);
  const [manualSignNextBtnDisabled, setmanualSignNextBtnDisabled] =
    useState(false);
  const [manualSignNextBtnloading, setmanualSignNextBtnloading] =
    useState(false);
  const [digiSignNextBtnDisabled, setdigiSignNextBtnDisabled] = useState(false);
  const [digiSignNextBtnLoading, setdigiSignNextBtnLoading] = useState(false);
  const [manualSignReason, setmanualSignReason] = useState('');
  const [docuSignCompleteUrl, setdocuSignCompleteUrl] = useState(false);
  const [docuSignCanceled, setdocuSignCanceled] = useState(false);
  const [docuSignClosed, setdocuSignClosed] = useState(false);
  const [IframeURL, setIframeURL] = useState('');
  const [hiddenLoader, sethiddenLoader] = useState(false);
  const [secondFactorEnabled, setsecondFactorEnabled] = useState(false);
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [customerSignOpen, setcustomerSignOpen] = useState(true);
  const [docSignPopupOpen, setdocSignPopupOpen] = useState(false);
  const [sendAgreementOpen, setsendAgreementOpen] = useState(false);
  const [sendAgreementInput, setsendAgreementInput] =
    useState(sendAgreementObj);
  const [sendAgrEmailEntered, setsendAgrEmailEntered] = useState(true);
  const [sendAgrEmailValid, setsendAgrEmailValid] = useState(true);
  const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
  const [inStoreFailOpen, setinStoreFailOpen] = useState(false);
  const [generateDocReq, setgenerateDocReq] = useState({
    signingUrlRequired: false,
    documentType: documentTypeArr,
    returnURL: window.location.origin,
    identifier: identifier,
    identifierType: customerSignUsingField == 'club' ? 'CUST' : 'AGR',
    signatureType: 1,
    isRemote: false,
    manualSignatureReasonId: null,
  });
  const customerCocustomerOptions = [
    { value: 'Customer', label: 'Customer' },
    { value: 'Co-customer', label: 'Co-customer' },
    {
      value: 'Both customer & co-customer',
      label: 'Both customer & co-customer',
    },
  ];
  const customerOptions = [{ value: 'Customer', label: 'Customer' }];
  const [currentRole, setcurrentRole] = useState('');
  const moduleName = 'Manual Sign In RentalAgmt';
  const [documentsToPrintArr, setdocumentsToPrintArr]: any = useState([]);
  const [printPopupOpen, setprintPopupOpen] = useState(false);
  const [constCustomersArr, setconstCustomersArr]: any = useState([]);
  const [documentsCreated, setdocumentsCreated] = useState(false);
  const [agreementNumber, setAgreementNumber] = useState('');
  const [splitCustomerName, setSplitCustomerName] = useState('');
  const [storeExceptionReason, setStoreExceptionReason] = useState('')
  const [identifierForSplitFlow, setIdentifierForSplitFlow] = useState('');
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false)
  useEffect(() => {
    console.log(
      'CustomersignatureProps',
      customerSignUsingField,
      identifier,
      customersArr,
      manualSignOptions,
      documentTypeArr
    );
    if (customerSignUsingModule != 'split') {
      if (customersArr.length === 1) {
        const tempGenerateReq: any = generateDocReq;
        const CustomerObj = customersArr[0];
        const tempArr = [
          {
            customerId: CustomerObj.customerId,
            customerName: CustomerObj.customerName,
            phoneNumber: CustomerObj.phoneNumber,
            emailId: CustomerObj.emailId,
            priority: 1,
          },
        ];
        tempGenerateReq.signers = tempArr;
        setgenerateDocReq(tempGenerateReq);
        setconstCustomersArr(tempArr);
        setcustomerSign({
          ...customerSign,
          customerName: CustomerObj.customerName,
          customerPhoneNumber: CustomerObj.phoneNumber,
          customerEmail: CustomerObj.emailId,
          presentToSign: 'Customer',
          bothcustomerselected: false,
        });
        setsendAgreementInput({
          ...sendAgreementInput,
          SendAgreementEmailTxt: CustomerObj.emailId,
        });
      } else if (customersArr.length > 1) {
        const tempGenerateReq: any = generateDocReq;
        const CustomerObj = customersArr[0];
        const CoCustomerObj = customersArr[1];
        const tempArr = [
          {
            customerId: CustomerObj.customerId,
            customerName: CustomerObj.customerName,
            phoneNumber: CustomerObj.phoneNumber,
            emailId: CustomerObj.emailId,
            priority: 1,
          },
          {
            customerId: CoCustomerObj.customerId,
            customerName: CoCustomerObj.customerName,
            phoneNumber: CoCustomerObj.phoneNumber,
            emailId: CoCustomerObj.emailId,
            priority: 2,
          },
        ];
        tempGenerateReq.signers = tempArr;
        setgenerateDocReq(tempGenerateReq);
        setconstCustomersArr(tempArr);
        setcustomerSign({
          ...customerSign,
          customerName: CustomerObj.customerName,
          customerPhoneNumber: CustomerObj.phoneNumber,
          customerEmail: CustomerObj.emailId,
          coCustomerName: CoCustomerObj.customerName,
          coCustomerPhoneNumber: CoCustomerObj.phoneNumber,
          coCustomerEmail: CoCustomerObj.emailId,
          presentToSign: bothCustomer,
          bothCustomerSelected: true,
        });
        setsendAgreementInput({
          ...sendAgreementInput,
          SendAgreementEmailTxt: CustomerObj.emailId,
        });
      }
    } else {
      console.log('splitzAgrDocPayloadArray', splitAgrDocPayloadArray);
      splitCustomerSignFlow(splitAgrDocPayloadArray);
    }
  }, []);
  useEffect(() => {
    if (TwoFactorCompleted === true) {
      setsecondFactorEnabled(false);
      const TempReqObj: any = generateDocReq;
      TempReqObj.signingUrlRequired = false;
      TempReqObj.identifier =
        customerSignUsingModule != 'split'
          ? Number(identifier)
          : Number(identifierForSplitFlow);
      TempReqObj.signatureType = 0;
      TempReqObj.isRemote = false;
      TempReqObj.manualSignatureReasonId = parseInt(manualSignReason);
      TempReqObj.signers = TempReqObj.signers.map((obj: any) => {
        obj.emailId = obj.emailId != null ? obj.emailId : 'test@example.com';
        return obj;
      });
      console.log('Genrate Doc Final req--->', TempReqObj);
      setcustomerSignOpen(false);
      setprintPopupOpen(true);
      const GenerateAgreementLoad = async () => {
        // propsfunction();
        const GenerateAgreementResponse = await GenerateAgreementDoc(
          TempReqObj
        );
        
        if (GenerateAgreementResponse.status === 200) {
          const payload: any = {
            storeException: [
              {
                identifierId: customerSignUsingModule != 'split'
                  ? Number(identifier)
                  : Number(identifierForSplitFlow),
                identifierType: 'AGREEMENT',
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                exceptionTypeRefCode: "APSO",
                exceptionText: storeExceptionReason


              }
            ]
          }
          storeException(payload)
          console.log('Genrate Doc Res--->', GenerateAgreementResponse);
          setdocumentsCreated(true);
          setIframeURL(GenerateAgreementResponse.data.signingUrl);
          const GetPrintDocReq = {
            identifier:
              customerSignUsingModule != 'split'
                ? String(identifier)
                : String(identifierForSplitFlow),
            identifierType: customerSignUsingField == 'club' ? 'CUST' : 'AGR',
            documentStatus: 'signed',
            emailId: customerSign.customerEmail,
            actionType: 'print',
          };
          //new changes starts here
          const LoopStartTime = Date.now();
          setTimeout(async function () {
            let GotSignedDoc = false;
            while (!GotSignedDoc) {
              const LoopEndTime = Date.now();
              const LoopTime = ((LoopEndTime - LoopStartTime) / 1000) % 60;
              if (LoopTime > 20) {
                setprintDocErrorOpen(true);
                setprintPopupOpen(false);
                break;
              }
              const GetPrintDocumnetResponse = await getPrintDocument(
                GetPrintDocReq
              );
              if (GetPrintDocumnetResponse.status == 200) {
                GotSignedDoc = true;
                const GetPrintDocumnetResponseData =
                  GetPrintDocumnetResponse.data;
                if (customerSignUsingModule == 'switchOut') {
                  const SwitchOutDocArr =
                    GetPrintDocumnetResponseData.documents.filter(
                      (val) => val.refCode == 'SWA'
                    );
                  setdocumentsToPrintArr(SwitchOutDocArr);
                } else if (customerSignUsingModule == 'loaner') {
                  const LoanerDocArr =
                    GetPrintDocumnetResponseData.documents.filter(
                      (val) => val.refCode == 'LOAN'
                    );
                  setdocumentsToPrintArr(LoanerDocArr);
                } else {
                  const GetPrintDocumnetResponseDataArray =
                    GetPrintDocumnetResponseData.documents;
                  setdocumentsToPrintArr(GetPrintDocumnetResponseDataArray);
                }
                break;
              } else if (
                GetPrintDocumnetResponse.status != 200 &&
                GetPrintDocumnetResponse.status != 400
              ) {
                setprintDocErrorOpen(true);
                setprintPopupOpen(false);
                break;
              }
            }
          }, 4000);

          //new changes ends here
        } else {
          setcustomerSignOpen(false);
          setprintPopupOpen(false);
          setsomethingWentWrongOpen(true);
        }
      };
      GenerateAgreementLoad();
    }
    if (TwoFactorCancelClick === true) {
      setsecondFactorEnabled(false);
      setcustomerSignOpen(true);
    }
  }, [TwoFactorCompleted, TwoFactorCancelClick]);
  useEffect(() => {
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRoleRes = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRoleRes);
          setcurrentRole(currentRoleRes);
        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setcurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, []);
  useEffect(() => {
    if (
      customerSign.presentToSign === 'Customer' ||
      customerSign.presentToSign === 'Co-customer'
    ) {
      if (docuSignCompleteUrl === true && docuSignCanceled === false) {
        setdocSignPopupOpen(false);
        setsendAgreementOpen(true);
      } else if (docuSignCompleteUrl === true && docuSignCanceled === true) {
        setdocSignPopupOpen(false);
        setcustomerSignOpen(true);
      }
    } else {
      if (docuSignCompleteUrl === true && docuSignCanceled === false) {
        const AgreementDocReq: any = generateDocReq;
        console.log('GenerateDocReq in cocus', generateDocReq);
        console.log('AgrCustomerObj', constCustomersArr);
        AgreementDocReq.signers = [constCustomersArr[1]];
        setIframeURL('');
        const GenerateAgrApiCall = async () => {
          console.log('CocustomerPayload', AgreementDocReq);
          const GenerateAgrRes = await GenerateAgreementDoc(AgreementDocReq);
          if (GenerateAgrRes.status === 200) {
            setdocumentsCreated(true);
            console.log('Im in cocustomer loop');
            if (GenerateAgrRes.data.signingUrl !== null) {
              setIframeURL(GenerateAgrRes.data.signingUrl);
              setcustomerSign({
                ...customerSign,
                presentToSign: 'Co-customer',
              });
            } else {
              setdocSignPopupOpen(false);
              setinStoreFailOpen(true);
            }
          } else {
            console.log('im in cocustomer sign fail loop');
            setdocSignPopupOpen(false);
            setcustomerSignOpen(true);
          }
        };
        GenerateAgrApiCall();
      } else if (docuSignCompleteUrl === true && docuSignCanceled === true) {
        setdocSignPopupOpen(false);
        setcustomerSignOpen(true);
      }
    }
  }, [docuSignCompleteUrl]);
  useEffect(() => {
    if (!customerSign.manualSignChecked) {
      if (documentGenerateCompleted) {
        setdigiSignNextBtnDisabled(false);
        setdigiSignNextBtnLoading(false);
        setmanualSignNextBtnloading(false);
      }
      if (!documentGenerateCompleted) {
        setdigiSignNextBtnDisabled(true);
        setdigiSignNextBtnLoading(true);
        setmanualSignNextBtnloading(true);
      }
    } else {
      if (documentGenerateCompleted && manualSignReason !== '') {
        setdigiSignNextBtnDisabled(false);
        setdigiSignNextBtnLoading(false);
        setmanualSignNextBtnloading(false);
        setmanualSignNextBtnDisabled(false);
      }
      if (documentGenerateCompleted && manualSignReason === '') {
        setdigiSignNextBtnDisabled(false);
        setdigiSignNextBtnLoading(false);
        setmanualSignNextBtnloading(false);
      }
      if (!documentGenerateCompleted) {
        setdigiSignNextBtnDisabled(true);
        setdigiSignNextBtnLoading(true);
        setmanualSignNextBtnloading(true);
      }
    }
  }, [documentGenerateCompleted]);
  useEffect(() => {
    const checkLevel = async () => {
      const emailButtonDisable = await level4AndWebCustomerCheck(customersArr[0]?.customerId);
      setEmailDisabled(emailButtonDisable)
    };

    checkLevel();
  }, []);

  const level4AndWebCustomerCheck = async (customerId: any) => {
    let result:boolean = false
    const storeNumber = window.sessionStorage.getItem('storeNumber')
    if (customerId && storeNumber) {
      const GetCustomerRes = await GetCustomer(customerId, storeNumber)
      console.log("GetCustomerResGetCustomerRes", GetCustomerRes)
      if (GetCustomerRes?.status == 200 && GetCustomerRes?.data) {
        const createdBy = GetCustomerRes?.data?.GetCustomer?.value?.createdBy || ''
        const salesLeadId: any = GetCustomerRes?.data?.GetCustomer?.value?.salesLeadId || '';
        if ((createdBy == 'EC' || createdBy == 'ECOM' || salesLeadId) && (GetCustomerRes?.data?.GetApproval?.value?.customerApprovalDetails?.verifyLevel == 4)) {
          result = true
        }
      }
    }
    return result
  }
  const resetCustomerSignPopup = () => {
    setcustomerSign({
      ...customerSign,
      digiSignChecked: true,
      manualSignChecked: false,
      inStoreChecked: true,
    });
    setmanualSignReason('');
    setStoreExceptionReason('');

  };

  const splitCustomerSignFlow = (splitAgrDocArray: any) => {
    setIframeURL('');
    const currentSplitCustomerSign = splitAgrDocArray.filter(
      (element: any) => element.signCompletion == '0'
    );
    if (currentSplitCustomerSign?.length > 0) {
      setAgreementNumber(currentSplitCustomerSign[0].agreementNumber);
      setSplitCustomerName(currentSplitCustomerSign[0].customerName);
      const tempGenerateReq: any = generateDocReq;
      const CustomerObj = currentSplitCustomerSign[0].customerArr[0];
      const tempArr = [
        {
          customerId: CustomerObj.customerId,
          customerName: CustomerObj.customerName,
          phoneNumber: CustomerObj.phoneNumber,
          emailId: CustomerObj.emailId,
          priority: 1,
        },
      ];
      tempGenerateReq.signers = tempArr;
      tempGenerateReq.identifier = Number(
        currentSplitCustomerSign[0].agreementId
      );
      setIdentifierForSplitFlow(currentSplitCustomerSign[0].agreementId);
      setgenerateDocReq({ ...tempGenerateReq });
      setconstCustomersArr(tempArr);
      setcustomerSign({
        ...customerSign,
        customerName: CustomerObj.customerName,
        customerPhoneNumber: CustomerObj.phoneNumber,
        customerEmail: CustomerObj.emailId,
        presentToSign: 'Customer',
        bothcustomerselected: false,
      });
      setsendAgreementInput({
        ...sendAgreementInput,
        SendAgreementEmailTxt: CustomerObj.emailId,
      });
      setcustomerSignOpen(true);
    } else {
      customerSignatureCompletedFn();
    }
  };

  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };
  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const customerSignTypeChange = (e: any) => {
    const SelectedSign = e.target.value;
    if (SelectedSign === 'DigitalSign') {
      setcustomerSign({
        ...customerSign,
        manualSignChecked: false,
        digitalSignSelected: true,
        digiSignChecked: true,
      });
    } else if (SelectedSign === 'ManualSign') {
      setmanualSignNextBtnDisabled(true);
      setmanualSignReason('');
      setStoreExceptionReason('')

      setcustomerSign({
        ...customerSign,
        manualSignChecked: true,
        digitalSignSelected: false,
        digiSignChecked: false,
      });
    }
  };
  const customerSignPlaceChange = (e: any) => {
    try {
      setcustomerSign({ ...customerSign, signPlaceChange: e.target.value });
      if (e.target.value === 'Email') {
        setcustomerSign({
          ...customerSign,
          emailChecked: true,
          inStoreChecked: false,
          emailDisabled: true,
        });
      } else if (e.target.value === 'InStore') {
        setcustomerSign({
          ...customerSign,
          emailChecked: false,
          inStoreChecked: true,
          emailDisabled: false,
        });
      }
    } catch {
      throw Error;
    }
  };
  const presentToSignChange = (e: any) => {
    try {
      const FieldName = e.target.name;
      const InputTxt = e.target.value;
      setcustomerSign({ ...customerSign, [FieldName]: InputTxt });
    } catch {
      throw Error;
    }
  };
  const customerPhoneOnchange = (e: any) => {
    try {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');

      const AgreementDocObj: any = generateDocReq;
      AgreementDocObj.signers[0].phoneNumber = cleaned.replace('+1', '');
      console.log('Customer phone onchange', cleaned);
      if (cleaned !== undefined) {
        AgreementDocObj.signers[0].phoneNumber = cleaned.replace('+', '');
        console.log('Customer phonenumber converted', cleaned.replace('+', ''));

        setcustomerNumValid(true);
        setcustomerNumEnterted(true);
        setcustomerSign({
          ...customerSign,
          ['customerPhoneNumber']: cleaned,
        });
        setgenerateDocReq(AgreementDocObj);
      }
    } catch {
      throw Error;
    }
  };
  const coCustomerPhoneOnchange = (e: any) => {
    try {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');

      const AgreementDocObj: any = generateDocReq;
      AgreementDocObj.signers[1].phoneNumber = cleaned.replace('+1', '');
      console.log(
        'CoCustomer phonenumber converted',
        cleaned.replace('+1', '')
      );
      setcoCustomerNumValid(true);
      setcoCustomerNumEnterted(true);
      setcustomerSign({
        ...customerSign,
        ['coCustomerPhoneNumber']: cleaned,
      });
      setgenerateDocReq(AgreementDocObj);
    } catch {
      throw Error;
    }
  };
  const customerSignTxtboxChange = (e: any) => {
    try {
      console.log('email changed');

      const FieldName = e.target.name;
      const FieldTxt = formatTextChange(e.target.value);
      const AgreementDocObj: any = generateDocReq;
      if (FieldName === 'CustomerEmail') {
        setcustomerSign({ ...customerSign, ['customerEmail']: FieldTxt });
        AgreementDocObj.signers[0].emailId = FieldTxt;
        console.log('Customer email changed', FieldTxt);
        setcustomerEmailEntered(true);
        setcustomerEmailValid(true);
      }
      if (FieldName === 'CoCustomerEmail') {
        setcustomerSign({ ...customerSign, ['coCustomerEmail']: FieldTxt });
        AgreementDocObj.signers[1].emailId = FieldTxt;
        console.log('CoCustomer email changed', FieldTxt);
        setcoCustomerEmailEntered(true);
        setcoCustomerEmailValid(true);
      }
    } catch {
      throw Error;
    }
  };
  const customerSignCloseOrCancelClick = () => {
    customerSignatureCanceledFn();
  };
  const customerSignNextClick = async () => {
    if (level4SecondFactorRequired == true && level4SecondFactorCompleted == false && customerSign.emailChecked && !customerSign?.manualSignChecked) {
      setLevel4SecondFactorEnabled(true)
      setcustomerSignOpen(false);
    }
    else {
      try {
        setdocuSignCanceled(false);
        setdocuSignCompleteUrl(false);
        const TempReqObj: any = generateDocReq;
        console.log('TempReqObj ', TempReqObj);
        const BothSingers: any = TempReqObj.signers;
        if (customerSign.digiSignChecked === true) {
          console.log('digi sign ', customerSign);
          const signers: any = [];
          const regex =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        const CustomerEmailValidation = regex.test(customerSign.customerEmail);
        const CoCustomerEmailValidation = regex.test(
          customerSign.coCustomerEmail
        );

        let validCustomerNeeded = true;
        let validCoCustomerNeeded = true;
        let validBothCustomerNeeded = true;
        let customerValidationCount = 0;
        let coCustomerValidationCount = 0;
        let BothCustomerValidationCount = 0;

        // Customer present to sign
        if (customerSign.presentToSign === 'Customer') {
          // Phone number and email validation
          customerSign.customerPhoneNumber !== undefined &&
          customerSign.customerPhoneNumber !== ''
            ? customerSign.customerPhoneNumber.length !== 10
              ? (setcustomerNumValid(false), setcustomerNumEnterted(false))
              : customerValidationCount++
            : setcustomerNumEnterted(false);
          CustomerEmailValidation
            ? customerValidationCount++
            : (setcustomerEmailEntered(false),
              customerSign.customerEmail === ''
                ? null
                : setcustomerEmailValid(false));

          console.log('customerValidationCount ', customerValidationCount);
          // After validation
          if (customerValidationCount === 2) {
            signers.push(TempReqObj.signers[0]);
          }
        } else {
          validCustomerNeeded = false;
        }
        // Co-customer present to sign
        if (customerSign.presentToSign === 'Co-customer') {
          // Phone number and email validation
          customerSign.coCustomerPhoneNumber !== undefined &&
          customerSign.coCustomerPhoneNumber !== ''
            ? customerSign.coCustomerPhoneNumber.length !== 10
              ? (setcoCustomerNumValid(false), setcoCustomerNumEnterted(false))
              : coCustomerValidationCount++
            : setcoCustomerNumEnterted(false);
          CoCustomerEmailValidation
            ? coCustomerValidationCount++
            : (setcoCustomerEmailEntered(false),
              customerSign.coCustomerEmail === ''
                ? null
                : setcoCustomerEmailValid(false));

          console.log('coCustomerValidationCount ', coCustomerValidationCount);
          // After validation
          if (coCustomerValidationCount === 2) {
            TempReqObj.signers[1] !== undefined
              ? signers.push(TempReqObj.signers[1])
              : signers.push(TempReqObj.signers[0]);
            // signers.push(TempReqObj.signers[1]);
          }
        } else {
          console.log('validCoCustomerNeeded false');
          validCoCustomerNeeded = false;
        }
        if (customerSign.presentToSign === bothCustomer) {
          //Customer phone and email validation
          customerSign.customerPhoneNumber !== undefined &&
          customerSign.customerPhoneNumber !== ''
            ? customerSign.customerPhoneNumber.length !== 10
              ? (setcustomerNumValid(false), setcustomerNumEnterted(false))
              : customerValidationCount++
            : setcustomerNumEnterted(false);
          CustomerEmailValidation
            ? customerValidationCount++
            : (setcustomerEmailEntered(false),
              customerSign.customerEmail === ''
                ? null
                : setcustomerEmailValid(false));
          //Co-customer phone and email validation
          customerSign.coCustomerPhoneNumber !== undefined &&
          customerSign.coCustomerPhoneNumber !== ''
            ? customerSign.coCustomerPhoneNumber.length !== 10
              ? (setcoCustomerNumValid(false), setcoCustomerNumEnterted(false))
              : coCustomerValidationCount++
            : setcoCustomerNumEnterted(false);
          CoCustomerEmailValidation
            ? coCustomerValidationCount++
            : (setcoCustomerEmailEntered(false),
              customerSign.coCustomerEmail === ''
                ? null
                : setcoCustomerEmailValid(false));
          if (
            customerValidationCount === 2 &&
            coCustomerValidationCount === 2
          ) {
            BothCustomerValidationCount = 2;
            signers.push(TempReqObj.signers[0]);
          }
        } else {
          console.log('validBothCustomerNeeded false');
          validBothCustomerNeeded = false;
        }

        console.log('signer ', signers);
        console.log(
          'validCustomerNeeded',
          validCustomerNeeded,
          customerValidationCount,
          validCoCustomerNeeded,
          coCustomerValidationCount
        );

        if (
          (!validCustomerNeeded || customerValidationCount === 2) &&
          (!validCoCustomerNeeded || coCustomerValidationCount === 2) &&
          (!validBothCustomerNeeded || BothCustomerValidationCount === 2)
        ) {
          //TempReqObj.identifier = identifier;
          TempReqObj.signatureType = 1;
          TempReqObj.manualSignatureReasonId = null;
          TempReqObj.isRemote = customerSign.emailChecked ? true : false;
          if (
            customerSign.presentToSign === bothCustomer &&
            customerSign.emailChecked === true
          ) {
            TempReqObj.signers = BothSingers;
          } else {
            TempReqObj.signers = signers;
          }

          if (customerSign.inStoreChecked) {
            TempReqObj.signingUrlRequired = true;

            TempReqObj.isRemote = false;

            console.log('Genrate Doc Final req--->', TempReqObj);
            setcustomerSignOpen(false);
            setdocSignPopupOpen(true);

            const GenerateAgreementResponse = await GenerateAgreementDoc(
              TempReqObj
            );
            console.log('Genrate Doc res--->', GenerateAgreementResponse);
            if (GenerateAgreementResponse.status === 200) {
              if (GenerateAgreementResponse.data.signingUrl != null) {
                setdocumentsCreated(true);
                setIframeURL(GenerateAgreementResponse.data.signingUrl);
              } else {
                setinStoreFailOpen(true);
                setcustomerSignOpen(false);
                setdocSignPopupOpen(false);
              }
            } else {
              setsomethingWentWrongOpen(true);
              setcustomerSignOpen(false);
              setdocSignPopupOpen(false);
            }
          } else if (customerSign.emailChecked) {
            TempReqObj.signingUrlRequired = false;
            TempReqObj.isRemote = true;

            console.log('Generate Doc Final req--->', TempReqObj);
            sethiddenLoader(true);
            const GenerateAgreementResponse = await GenerateAgreementDoc(
              TempReqObj
            );
            if (GenerateAgreementResponse.status === 200) {
              console.log('Genrate Doc Res--->', GenerateAgreementResponse);
              setdocumentsCreated(true);
              sethiddenLoader(false);
              setcustomerSignOpen(false);
              setIframeURL(GenerateAgreementResponse.data.signingUrl);
              if (customerSignUsingModule != 'split') {
                emailcompletedFn !== undefined
                  ? emailcompletedFn()
                  : customerSignatureCompletedFn();
              } else {
                resetCustomerSignPopup();
                const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
                  (obj: any) => obj.agreementNumber == agreementNumber
                );
                const splitAgrDocArray = splitAgrDocPayloadArray;
                splitAgrDocArray[currentAgrIndex].signCompletion = '1';
                splitCustomerSignFlow(splitAgrDocArray);
              }
            } else {
              sethiddenLoader(false);
              setcustomerSignOpen(false);
              setsomethingWentWrongOpen(true);
            }
          }
        }
      }
      // manual sign
      else if (customerSign.manualSignChecked === true) {
        console.log('Manual signature triggered');
        setTwoFactorCancelClick(false);
        setTwoFactorCompleted(false);
        setsecondFactorEnabled(true);
        setcustomerSignOpen(false);
      }
    } catch {
      throw Error;
    }
  }
};
  const docuSignCloseClick = () => {
    try {
      setdocuSignClosed(true);
      setdocSignPopupOpen(false);
      setIframeURL('');
      customerSignatureCanceledFn();
    } catch {
      throw Error;
    }
  };
  const docuSignOnload = () => {
    try {
      console.log('Iframe onload triggered');
      setdocuSignCompleteUrl(false);
      const temp = document.getElementById('IframeID') as HTMLIFrameElement;
      const location: any = temp.contentWindow?.location.href;
      console.log('Iframe Url in location', location);
      const CheckIframe: any = location.includes(window.location.origin);
      const CancelCheck: any = location.includes('cancel');
      console.log('Iframe url checking ', CheckIframe);
      if (CheckIframe === true && CancelCheck === false) {
        setdocuSignCompleteUrl(true);
      } else if (CheckIframe === true && CancelCheck === true) {
        setdocuSignCompleteUrl(true);
        setdocuSignCanceled(true);
      } else {
        setdocuSignCompleteUrl(false);
      }
    } catch {
      throw Error;
    }
  };
  const inStoreFlowFailOkClick = () => {
    setinStoreFailOpen(false);
    customerSignatureCanceledFn();
  };
  const somethingWentWrongOkClick = () => {
    setsomethingWentWrongOpen(false);
    customerSignatureCanceledFn();
  };
  const inStoreFlowFailPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            Cannot proceed with instore signing at this moment
          </Typography>
          <Typography className={classes.formLabel}>
            {' '}
            Please try other signing options
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => inStoreFlowFailOkClick()}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const printDocErrorOpenPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            Unable to fetch the generated documents at this moment ...
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              if (customerSignUsingModule != 'split') {
                setprintDocErrorOpen(false);
                customerSignatureCompletedFn();
              } else {
                setprintDocErrorOpen(false);
                resetCustomerSignPopup();
                const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
                  (obj: any) => obj.agreementNumber == agreementNumber
                );
                const splitAgrDocArray = splitAgrDocPayloadArray;
                splitAgrDocArray[currentAgrIndex].signCompletion = '1';
                splitCustomerSignFlow(splitAgrDocArray);
              }
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => somethingWentWrongOkClick()}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const docuSignPopup = () => {
    return (
      <Grid className={`${classes.w100} ${classes.floatLeft}`}>
        <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
          {IframeURL === '' ? (
            <Grid className={classes.iframeClass}>
              <CircularProgress
                style={{ marginTop: '200px', marginLeft: '575px' }}
                size={30}
              />
            </Grid>
          ) : (
            <iframe
              src={IframeURL}
              className={classes.iframeClass}
              id="IframeID"
              title="Iframe"
              onLoad={() => docuSignOnload()}
            ></iframe>
          )}
        </Grid>
      </Grid>
    );
  };
  const manualSignReasonOnchange = (e: any) => {
    try {
      const InputValue = e.target.value;
      setmanualSignReason(InputValue);
      setStoreExceptionReason(e?.currentTarget?.innerText)
      if (InputValue !== '' && documentGenerateCompleted) {
        setmanualSignNextBtnDisabled(false);
      } else {
        setmanualSignNextBtnDisabled(true);
      }
    } catch {
      throw Error;
    }
  };
  const printCloseClick = () => {
    try {
      if (customerSignUsingModule != 'split') {
        setprintPopupOpen(false);
        customerSignatureCompletedFn();
      } else {
        resetCustomerSignPopup();
        const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
          (obj: any) => obj.agreementNumber == agreementNumber
        );
        const splitAgrDocArray = splitAgrDocPayloadArray;
        splitAgrDocArray[currentAgrIndex].signCompletion = '1';
        splitCustomerSignFlow(splitAgrDocArray);
      }
    } catch {
      throw Error;
    }
  };
  const printPopupOkClick = () => {
    try {
      console.log('PrintOk click');
      if (customerSignUsingModule != 'split') {
        setprintPopupOpen(false);
        customerSignatureCompletedFn();
      } else {
        resetCustomerSignPopup();
        const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
          (obj: any) => obj.agreementNumber == agreementNumber
        );
        const splitAgrDocArray = splitAgrDocPayloadArray;
        splitAgrDocArray[currentAgrIndex].signCompletion = '1';
        splitCustomerSignFlow(splitAgrDocArray);
      }
    } catch {
      throw Error;
    }
  };
  const sendAgreementCloseClick = () => {
    try {
      setsendAgreementOpen(false);
      setsendAgreementInput({
        ...sendAgreementInput,
        SendAgreementEmail: true,
        SendAgreementPrint: false,
      });
      if (customerSignUsingModule != 'split') {
        customerSignatureCompletedFn();
      } else {
        resetCustomerSignPopup();
        const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
          (obj: any) => obj.agreementNumber == agreementNumber
        );
        const splitAgrDocArray = splitAgrDocPayloadArray;
        splitAgrDocArray[currentAgrIndex].signCompletion = '1';
        splitCustomerSignFlow(splitAgrDocArray);
      }
    } catch {
      throw Error;
    }
  };
  const sendAgreementEmailchoose = () => {
    setsendAgreementInput({
      ...sendAgreementInput,
      SendAgreementEmail: true,
      SendAgreementPrint: false,
    });
  };
  const sendAgreementPrintChoose = () => {
    setsendAgreementInput({
      ...sendAgreementInput,
      SendAgreementEmail: false,
      SendAgreementPrint: true,
    });
  };
  const sendAgreementEmailTxtChange = (e: any) => {
    try {
      setsendAgreementInput({
        ...sendAgreementInput,
        SendAgreementEmailTxt: e.target.value,
      });
      setsendAgrEmailValid(true);
      setsendAgrEmailEntered(true);
    } catch {
      throw Error;
    }
  };
  const sendEmailFn = () => {
    const GetPrintDocReq = {
      identifier: String(identifier),
      identifierType: customerSignUsingField == 'club' ? 'CUST' : 'AGR',
      customerLastName: customersArr[0].lastName,
      documentStatus: 'signed',
      emailId: sendAgreementInput.SendAgreementEmailTxt,
      actionType: 'email',
    };
    console.log('GetPrintDocReq', GetPrintDocReq);
    setTimeout(async function () {
      const GetPrintDocumnetResponse = await getPrintDocument(GetPrintDocReq);
      console.log('GetPrintDocumentResponse', GetPrintDocumnetResponse.data);
    }, 15000);
  };
  const sendAgreementNextClick = async () => {
    try {
      if (sendAgreementInput.SendAgreementEmail === true) {
        console.log('send email triggered');
        console.log('EmailTxt', sendAgreementInput.SendAgreementEmailTxt);
        const regex =
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const EmailResult = regex.test(
          sendAgreementInput.SendAgreementEmailTxt
        );
        console.log('EmailResult', EmailResult);
        if (EmailResult === true) {
          console.log('identifier', identifier);
          console.log('CustomerID', customersArr[0]?.customerId);
          console.log('PrimaryCustomerInfo', customersArr[0]);
          console.log('firstName', customersArr[0]?.firstName);
          console.log('lastName', customersArr[0]?.lastName);
          console.log(
            'SendAgreementEmailTxt',
            sendAgreementInput.SendAgreementEmailTxt
          );
          setsendAgreementOpen(false);
          if (customerSignUsingField == 'club') {
            console.log('inside club send email');
            sethiddenLoader(true);
            const GetPrintDocReq = {
              identifier:
                customerSignUsingModule != 'split'
                  ? String(identifier)
                  : String(identifierForSplitFlow),
              identifierType: 'CUST',
              customerLastName: customersArr[0].lastName,
              documentStatus: 'signed',
              emailId: sendAgreementInput.SendAgreementEmailTxt,
              actionType: 'email',
            };
            console.log('GetPrintDocReq', GetPrintDocReq);
            setTimeout(async function () {
              console.log('inside club send email triggered');
              const GetPrintDocumnetResponse = await getPrintDocument(
                GetPrintDocReq
              );
              console.log(
                'GetPrintDocumentResponse',
                GetPrintDocumnetResponse.data
              );
              sethiddenLoader(false);
              if (customerSignUsingModule != 'split') {
                customerSignatureCompletedFn();
              } else {
                resetCustomerSignPopup();
                const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
                  (obj: any) => obj.agreementNumber == agreementNumber
                );
                const splitAgrDocArray = splitAgrDocPayloadArray;
                splitAgrDocArray[currentAgrIndex].signCompletion = '1';
                splitCustomerSignFlow(splitAgrDocArray);
              }
            }, 15000);
          } else {
            if (customerSignUsingModule != 'split') {
              customerSignUsingField == 'deliveryMethod'
                ? emailcompletedFn()
                : customerSignatureCompletedFn();
            } else {
              resetCustomerSignPopup();
              const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
                (obj: any) => obj.agreementNumber == agreementNumber
              );
              const splitAgrDocArray = splitAgrDocPayloadArray;
              splitAgrDocArray[currentAgrIndex].signCompletion = '1';
              splitCustomerSignFlow(splitAgrDocArray);
            }

            const GetPrintDocReq = {
              identifier:
                customerSignUsingModule != 'split'
                  ? String(identifier)
                  : String(identifierForSplitFlow),
              identifierType: 'AGR',
              customerLastName: customersArr[0].lastName,
              documentStatus: 'signed',
              emailId: sendAgreementInput.SendAgreementEmailTxt,
              actionType: 'email',
            };
            console.log('GetPrintDocReq', GetPrintDocReq);
            setTimeout(async function () {
              const GetPrintDocumnetResponse = await getPrintDocument(
                GetPrintDocReq
              );
              console.log(
                'GetPrintDocumentResponse',
                GetPrintDocumnetResponse.data
              );
            }, 15000);
          }
        } else if (sendAgreementInput.SendAgreementEmailTxt === '') {
          setsendAgrEmailEntered(false);
        } else if (EmailResult === false) {
          setsendAgrEmailValid(false);
        }
      }

      if (sendAgreementInput.SendAgreementPrint === true) {
        console.log('print triggered');
        setsendAgreementOpen(false);
        setsendAgreementOpen(false);
        setprintPopupOpen(true);
        const GetPrintDocReq = {
          identifier:
            customerSignUsingModule != 'split'
              ? String(identifier)
              : String(identifierForSplitFlow),
          identifierType: customerSignUsingField == 'club' ? 'CUST' : 'AGR',
          documentStatus: 'signed',
          emailId: sendAgreementInput.SendAgreementEmailTxt,
          actionType: 'print',
        };
        const LoopStartTime = Date.now();
        setTimeout(async function () {
          let GotSignedDoc = false;
          let TempRes = undefined;
          while (!GotSignedDoc) {
            const LoopEndTime = Date.now();
            const LoopTime = ((LoopEndTime - LoopStartTime) / 1000) % 60;
            if (LoopTime > 20) {
              if (TempRes) {
                setdocumentsToPrintArr(TempRes);
              } else {
                setprintDocErrorOpen(true);
                setprintPopupOpen(false);
              }
              break;
            }
            const GetPrintDocumnetResponse = await getPrintDocument(
              GetPrintDocReq
            );
            if (GetPrintDocumnetResponse.status === 200) {
              const GetPrintDocumnetResponseData =
                GetPrintDocumnetResponse.data;
              if (
                GetPrintDocumnetResponseData.documents[0].documentUrl.includes(
                  'unsigned'
                ) === false
              ) {
                GotSignedDoc = true;
                //
                if (customerSignUsingModule == 'switchOut') {
                  const SwitchOutDocArr =
                    GetPrintDocumnetResponseData.documents.filter(
                      (val) => val.refCode == 'SWA'
                    );
                  setdocumentsToPrintArr(SwitchOutDocArr);
                } else {
                  const GetPrintDocumnetResponseDataArray =
                    GetPrintDocumnetResponseData.documents;
                  setdocumentsToPrintArr(GetPrintDocumnetResponseDataArray);
                }
                //
                break;
              } else {
                if (customerSignUsingModule == 'switchOut') {
                  const SwitchOutDocArr =
                    GetPrintDocumnetResponseData.documents.filter(
                      (val) => val.refCode == 'SWA'
                    );
                  TempRes = SwitchOutDocArr;
                } else {
                  TempRes = GetPrintDocumnetResponseData.documents;
                }
              }
            } else if (
              GetPrintDocumnetResponse.status != 200 &&
              GetPrintDocumnetResponse.status != 400
            ) {
              setprintDocErrorOpen(true);
              setprintPopupOpen(false);
              break;
            }
          }
        }, 4000);
      }
    } catch {
      throw Error;
    }
  };
  const customerSignaturePopup = () => {
    return (
      <Grid className={`${classes.p3} ${classes.pb0}`} id="Customer-Signature">
        <Grid className={classes.mb3}>
          {customerSignUsingModule == 'split' ? (
            <Card
              className={`${classes.racSkyBlue} ${classes.bluecards} `}
              style={{
                padding: '5px 5px',
                backgroundColor: '#EFF4FF',
                textAlign: 'center',
                marginBottom: '5px',
                marginTop: '-25px',
              }}
            >
              <label
                className={classes.fontstyle}
                style={{ fontWeight: 'bold', fontSize: '13px' }}
              >
                Customer Name :
              </label>
              <label
                style={{
                  fontWeight: 'bolder',
                  marginLeft: '5px',
                  fontSize: '13px',
                }}
                className={classes.fontstyle}
              >
                {splitCustomerName}
              </label>
              <label
                style={{
                  marginBottom: '12px',
                  fontWeight: 'bold',
                  marginLeft: '35px',
                  fontSize: '13px',
                }}
                className={classes.fontstyle}
              >
                Agreement Number :
              </label>
              <label
                style={{
                  fontWeight: 'bolder',
                  marginLeft: '5px',
                  fontSize: '13px',
                }}
                className={classes.fontstyle}
              >
                {' '}
                {agreementNumber}
              </label>
            </Card>
          ) : null}
          <label
            className={`${classes.formLabel} ${classes.w100} ${classes.mb1} ${classes.fontHeading}`}
          >
            Select Signature Options
          </label>
          <Grid
            className={`${classes.w100} ${classes.mt2} ${classes.mb2} ${classes.floatLeft}`}
          >
            <Grid className={classes.floatLeft}>
              <RACRadio
                value="DigitalSign"
                checked={customerSign.digiSignChecked}
                onChange={(e) => customerSignTypeChange(e)}
                label="Digital Signature"
                style={{ fontSize: 'bold' }}
              />
            </Grid>
            <Grid className={`${classes.floatLeft} ${classes.ms3}`}>
              <RACRadio
                value="ManualSign"
                checked={customerSign.manualSignChecked}
                onChange={(e) => customerSignTypeChange(e)}
                label="Manual Signature"
                style={{ fontSize: 'bold' }}
              />
            </Grid>
          </Grid>
        </Grid>
        {customerSign.digitalSignSelected === true ? (
          <div>
            <Grid container spacing={2} className={classes.mb3}>
              <Grid item md={6}>
                {customerSign.bothCustomerSelected === true ? (
                  <Grid>
                    <RACSelect
                      inputLabel={'Who is present to sign?'}
                      defaultValue={customerSign.presentToSign}
                      onChange={(e) => {
                        e.target.name = 'presentToSign';
                        presentToSignChange(e);
                      }}
                      options={customerCocustomerOptions}
                    />
                  </Grid>
                ) : (
                  <Grid>
                    <RACSelect
                      inputLabel={'Who is present to sign?'}
                      defaultValue={customerSign.presentToSign}
                      onChange={(e) => {
                        e.target.name = 'presentToSign';
                        presentToSignChange(e);
                      }}
                      options={customerOptions}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item md={6}>
                <label className={`${classes.formLabel} ${classes.w100}`}>
                  Where are you signing?
                </label>
                <Grid>
                  <Grid className={classes.floatLeft}>
                    <RACRadio
                      value="InStore"
                      checked={customerSign.inStoreChecked}
                      onChange={(e) => customerSignPlaceChange(e)}
                      label="In Store"
                    />
                  </Grid>
                  <Grid className={`${classes.floatLeft} ${classes.ms2}`} title={emailDisabled ? "Level - 4 customer must sign their agreement in-store" : ""}>
                    <RACRadio
                      value="Email"
                      checked={customerSign.emailChecked}
                      onChange={(e) => customerSignPlaceChange(e)}
                      label="Email"
                      disabled={emailDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <label className={classes.formLabel} htmlFor="PhoneNumber">
                  Phone Number
                  <span className={classes.mandatoryfield}>*</span>
                </label>
                <div style={{ width: '100%' }}>
                  {customerSign.presentToSign === bothCustomer ? (
                    <div>
                      <label
                        className={classes.formLabel}
                        style={{
                          float: 'left',
                          width: '50%',
                        }}
                        title={customerSign.customerName}
                      >
                        {hideLengthyText(customerSign.customerName, 10, '...')}
                      </label>

                      <RACTextbox
                        type="phoneno"
                        inputlabel=""
                        maxlength={10}
                        id="customer_phonenumber"
                        name="customerPhoneNumber"
                        value={('' + customerSign.customerPhoneNumber)
                          .replace(/\D/g, '')
                          .replace(
                            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                            '($1) $2-$3'
                          )}
                        disabled={customerSign.emailDisabled}
                        OnChange={(e: any) => customerPhoneOnchange(e)}
                        isCurrency={false}
                      />

                      <label
                        style={{
                          color: 'red',
                          fontSize: 'small',
                          marginLeft: '3%',
                          visibility:
                            customerNumEnterted === false
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        {customerNumValid === false
                          ? validPhoneError
                          : emptyPhoneError}
                      </label>
                      <label
                        className={classes.formLabel}
                        style={{
                          float: 'left',
                          width: '100%',
                        }}
                        title={customerSign.coCustomerName}
                      >
                        {hideLengthyText(
                          customerSign.coCustomerName,
                          10,
                          '...'
                        )}
                      </label>
                      <RACTextbox
                        type="phoneno"
                        inputlabel=""
                        maxlength={10}
                        id="cocustomer_phonenumber"
                        name="coCustomerPhoneNumber"
                        value={('' + customerSign.coCustomerPhoneNumber)
                          .replace(/\D/g, '')
                          .replace(
                            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                            '($1) $2-$3'
                          )}
                        OnChange={(e: any) => coCustomerPhoneOnchange(e)}
                        isCurrency={false}
                        disabled={customerSign.emailDisabled}
                      />
                      <label
                        style={{
                          color: 'red',
                          fontSize: 'small',
                          marginLeft: '3%',
                          visibility:
                            coCustomerNumEnterted === false
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        {coCustomerNumValid === false
                          ? validPhoneError
                          : emptyPhoneError}
                      </label>
                    </div>
                  ) : null}
                  {customerSign.presentToSign === 'Customer' ? (
                    <div>
                      <label
                        className={classes.formLabel}
                        style={{
                          float: 'left',
                          width: '50%',
                        }}
                        title={customerSign.customerName}
                      >
                        {hideLengthyText(customerSign.customerName, 10, '...')}
                      </label>
                      <RACTextbox
                        type="phoneno"
                        inputlabel=""
                        maxlength={10}
                        id="customer_phonenumber"
                        name="CustomerPhoneNumber"
                        value={('' + customerSign.customerPhoneNumber)
                          .replace(/\D/g, '')
                          .replace(
                            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                            '($1) $2-$3'
                          )}
                        OnChange={(e: any) => customerPhoneOnchange(e)}
                        isCurrency={false}
                        disabled={customerSign.emailDisabled}
                      />
                      <label
                        style={{
                          color: 'red',
                          fontSize: 'small',
                          marginLeft: '3%',
                          visibility:
                            customerNumEnterted === false
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        {customerNumValid === false
                          ? validPhoneError
                          : emptyPhoneError}
                      </label>
                    </div>
                  ) : null}
                  {customerSign.presentToSign === 'Co-customer' ? (
                    <div>
                      <label
                        className={classes.formLabel}
                        style={{
                          float: 'left',
                          width: '100%',
                        }}
                        title={customerSign.coCustomerName}
                      >
                        {hideLengthyText(
                          customerSign.coCustomerName,
                          10,
                          '...'
                        )}
                      </label>
                      <RACTextbox
                        type="phoneno"
                        inputlabel=""
                        maxlength={10}
                        id="cocustomer_phonenumber"
                        name="CoCustomerPhoneNumber"
                        value={('' + customerSign.coCustomerPhoneNumber)
                          .replace(/\D/g, '')
                          .replace(
                            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                            '($1) $2-$3'
                          )}
                        OnChange={(e: any) => coCustomerPhoneOnchange(e)}
                        isCurrency={false}
                        disabled={customerSign.emailDisabled}
                      />
                      <label
                        style={{
                          color: 'red',
                          fontSize: 'small',
                          marginLeft: '3%',
                          visibility:
                            coCustomerNumEnterted === false
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        {coCustomerNumValid === false
                          ? validPhoneError
                          : emptyPhoneError}
                      </label>
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid item md={6}>
                <label className={classes.formLabel} htmlFor="Email">
                  Email
                  <span className={classes.mandatoryfield}>*</span>
                </label>
                {customerSign.presentToSign === 'Customer' ? (
                  <div style={{ marginTop: 19 }}>
                    <RACTextbox
                      type="text"
                      inputlabel=""
                      maxlength={50}
                      id="customer_emailaddress"
                      name="CustomerEmail"
                      value={customerSign.customerEmail}
                      OnChange={(e: any) => customerSignTxtboxChange(e)}
                      isCurrency={false}
                    />
                    <label
                      style={{
                        color: 'red',
                        fontSize: 'small',
                        marginLeft: '3%',
                        visibility:
                          customerEmailEntered === false ? 'visible' : 'hidden',
                      }}
                    >
                      {customerEmailValid === false
                        ? validEmailError
                        : emptyEmailError}
                    </label>
                  </div>
                ) : null}
                {customerSign.presentToSign === 'Co-customer' ? (
                  <div style={{ marginTop: 19 }}>
                    <RACTextbox
                      type="text"
                      inputlabel=""
                      maxlength={50}
                      id="cocustomer_emailaddress"
                      name="CoCustomerEmail"
                      value={customerSign.coCustomerEmail}
                      OnChange={(e: any) => customerSignTxtboxChange(e)}
                      isCurrency={false}
                    />
                    <label
                      style={{
                        color: 'red',
                        fontSize: 'small',
                        marginLeft: '3%',
                        visibility:
                          coCustomerEmailEntered === false
                            ? 'visible'
                            : 'hidden',
                      }}
                    >
                      {coCustomerEmailValid === false
                        ? validEmailError
                        : emptyEmailError}
                    </label>
                  </div>
                ) : null}
                {customerSign.presentToSign === bothCustomer ? (
                  <div>
                    <div style={{ marginTop: 19 }}>
                      <RACTextbox
                        type="text"
                        inputlabel=""
                        maxlength={50}
                        id="customer_emailaddress"
                        name="CustomerEmail"
                        value={customerSign.customerEmail}
                        OnChange={(e: any) => customerSignTxtboxChange(e)}
                        isCurrency={false}
                      />
                      <label
                        style={{
                          color: 'red',
                          fontSize: 'small',
                          marginLeft: '3%',
                          visibility:
                            customerEmailEntered === false
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        {customerEmailValid === false
                          ? validEmailError
                          : emptyEmailError}
                      </label>
                    </div>
                    <div style={{ marginTop: 19 }}>
                      <RACTextbox
                        type="text"
                        inputlabel=""
                        maxlength={50}
                        id="cocustomer_emailaddress"
                        name="CoCustomerEmail"
                        value={customerSign.coCustomerEmail}
                        OnChange={(e: any) => customerSignTxtboxChange(e)}
                        isCurrency={false}
                      />
                      <label
                        style={{
                          color: 'red',
                          fontSize: 'small',
                          marginLeft: '3%',
                          visibility:
                            coCustomerEmailEntered === false
                              ? 'visible'
                              : 'hidden',
                        }}
                      >
                        {coCustomerEmailValid === false
                          ? validEmailError
                          : emptyEmailError}
                      </label>
                    </div>
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid md={12} className={`${classes.px3} ${classes.mt3}`}>
              <Grid className={classes.textRight}>
                <RACButton
                  name="CustomerSignCancel"
                  variant="outlined"
                  color="primary"
                  className={classes.mx1}
                  onClick={(e) => customerSignCloseOrCancelClick()}
                >
                  Cancel
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.mx1}
                  disabled={digiSignNextBtnDisabled}
                  onClick={() => customerSignNextClick()}
                  loading={digiSignNextBtnLoading}
                >
                  Next
                </RACButton>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid>
            <Typography className={`${classes.semiBold} ${classes.mb2}`}>
              Please specify the reason, why the digital signature was not
              obtained?
            </Typography>
            <Grid md={8} className={classes.mb3}>
              <div>
                <RACSelect
                  inputLabel={'Reason'}
                  defaultValue={manualSignReason}
                  onChange={(e) => manualSignReasonOnchange(e)}
                  options={manualSignOptions}
                />
              </div>
            </Grid>
            <Grid md={12} className={`${classes.px3} ${classes.mt3}`}>
              <Grid className={classes.textRight}>
                <RACButton
                  name="CustomerSignCancel"
                  variant="outlined"
                  color="primary"
                  className={classes.mx1}
                  onClick={(e) => customerSignCloseOrCancelClick()}
                >
                  Cancel
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.mx1}
                  disabled={manualSignNextBtnDisabled}
                  loading={manualSignNextBtnloading}
                  onClick={() => customerSignNextClick()}
                >
                  Next
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        )}{' '}
      </Grid>
    );
  };
  const sendAgreementPopup = () => {
    return (
      <Grid className={classes.p3}>
        <Grid container>
          <Grid item md={12} className={classes.mb3}>
            <Typography
              component="label"
              className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
            >
              How do you want the agreement to be shared?
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.mb3}>
            <RACRadio
              value={sendAgreementInput.SendAgreementEmail}
              checked={sendAgreementInput.SendAgreementEmail}
              onChange={() => sendAgreementEmailchoose()}
              label="Email"
            />
            <RACRadio
              value={sendAgreementInput.SendAgreementPrint}
              checked={sendAgreementInput.SendAgreementPrint}
              onChange={() => sendAgreementPrintChoose()}
              label="Print"
            />
          </Grid>
          <Grid item md={6}>
            {sendAgreementInput.SendAgreementEmail === true ? (
              <div>
                <RACTextbox
                  inputlabel="Email"
                  inputLabelClassname="form-label"
                  name="Email"
                  value={sendAgreementInput.SendAgreementEmailTxt}
                  OnChange={(e) => sendAgreementEmailTxtChange(e)}
                  isCurrency={false}
                  type="email"
                />
                {sendAgrEmailEntered === false ? (
                  <label
                    style={{
                      color: 'red',
                      fontSize: 'small',
                      marginLeft: '3%',
                    }}
                  >
                    Please enter Email
                  </label>
                ) : null}
                {sendAgrEmailValid === false ? (
                  <label
                    style={{
                      color: 'red',
                      fontSize: 'small',
                      marginLeft: '3%',
                    }}
                  >
                    Please enter Valid Email
                  </label>
                ) : null}
              </div>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            name="SendAgreementCancel"
            onClick={() => {
              if (customerSignUsingModule != 'split') {
                setsendAgreementOpen(false);
                customerSignatureCompletedFn();
              } else {
                resetCustomerSignPopup();
                const currentAgrIndex = splitAgrDocPayloadArray.findIndex(
                  (obj: any) => obj.agreementNumber == agreementNumber
                );
                const splitAgrDocArray = splitAgrDocPayloadArray;
                splitAgrDocArray[currentAgrIndex].signCompletion = '1';
                splitCustomerSignFlow(splitAgrDocArray);
              }
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            name=""
            onClick={() => sendAgreementNextClick()}
          >
            {customerSignUsingModule === 'switchOut' ? 'Finish' : 'Next'}
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const viewPDFclick = (b64File: any, docuURL: any) => {
    console.log('viewPDFclick triggered');
    const urlArray = docuURL.split('.');
    const docuType = urlArray[urlArray.length - 1];
    const blobResult = b64toBlob(b64File, `application/${docuType}`);
    const fileURL = URL.createObjectURL(blobResult);
    window.open(fileURL);
  };
  const printDocumentsListBind = () => {
    //build triggered
    if (documentsToPrintArr.length > 0) {
      return documentsToPrintArr.map((element, index) => {
        return (
          <Grid item md={12} className={classes.mb3} key={index}>
            <PDFImage></PDFImage>
            <label className={`${classes.formLabel} ${classes.ms1}`}>
              {element.documentName}
            </label>
            <span
              style={{ cursor: 'pointer' }}
              className={`${classes.racpadLink} ${classes.ms2} ${classes.textRight}`}
              onClick={() => viewPDFclick(element.file, element.documentUrl)}
            >
              View PDF
            </span>
          </Grid>
        );
      });
    }
  };
  const printingPopup = () => {
    return (
      <Grid
        className={classes.p3}
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        {documentsToPrintArr.length === 0 ? (
          <Grid item md={12} className={classes.mb3}>
            <Typography
              variant="h6"
              style={{ textAlign: 'center' }}
              className={classes.mt3}
            >
              Please wait…
            </Typography>
            <Typography
              variant="h6"
              style={{ textAlign: 'center' }}
              className={classes.mt3}
            >
              We are fetching the agreement documents for you!
            </Typography>
            <CircularProgress
              style={{ marginTop: '30px', marginLeft: '180px' }}
              size={23}
            />
          </Grid>
        ) : (
          <Grid>
            {printDocumentsListBind()}
            <Grid
              item
              md={12}
              className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
            >
              <RACButton
                className={classes.mx1}
                onClick={() => printPopupOkClick()}
                variant="contained"
                color="primary"
              >
                OK
              </RACButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };
  // Level 4 customer SLACompleted function
  const SLACompleted = () => {
    setLevel4SecondFactorEnabled(false)
    level4SecondFactorCompleted = true
    setLevel4SecondFactorCompleted(level4SecondFactorCompleted)
    customerSignNextClick()
  };

  // Level 4 customer cancel click function
  const SLACancel = () => {
    setLevel4SecondFactorEnabled(false)
    setcustomerSignOpen(true);
    level4SecondFactorCompleted = false
    setLevel4SecondFactorCompleted(level4SecondFactorCompleted)
  };

  return (
    <Grid>
      {hiddenLoader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <RACModalCard
        isOpen={customerSignOpen}
        closeIcon={true}
        maxWidth="sm"
        children={customerSignaturePopup()}
        borderRadius={'20px !important'}
        title="Customer Signature"
        TitleVariantClassName={classes.popupTitle}
        onClose={() => customerSignCloseOrCancelClick()}
      />
      <RACModalCard
        isOpen={docSignPopupOpen}
        closeIcon={true}
        onClose={() => docuSignCloseClick()}
        maxWidth="md"
        children={docuSignPopup()}
        className={`${classes.customModal}`}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={printPopupOpen}
        closeIcon={true}
        maxWidth="xs"
        title="View Documents"
        TitleVariantClassName={classes.popupTitle}
        onClose={() => printCloseClick()}
        children={printingPopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={somethingWentWrongOpen}
        closeIcon={false}
        maxWidth="xs"
        children={somethingWentWrongPopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={printDocErrorOpen}
        closeIcon={false}
        maxWidth="xs"
        children={printDocErrorOpenPopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={inStoreFailOpen}
        closeIcon={false}
        maxWidth="xs"
        children={inStoreFlowFailPopup()}
        borderRadius={'20px !important'}
      />
      <RACModalCard
        isOpen={sendAgreementOpen}
        closeIcon={true}
        maxWidth="sm"
        title="Send Agreement"
        TitleVariantClassName={classes.popupTitle}
        children={sendAgreementPopup()}
        onClose={() => sendAgreementCloseClick()}
        borderRadius={'20px !important'}
      />
      {secondFactorEnabled === true ? (
        <SecondFactor
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          moduleName={moduleName}
          currentRole={currentRole}
        />
      ) : null}
      {level4SecondFactorEnabled === true ? (
        <SecondFactor
          setTwoFactorCancelClick={SLACancel}
          setTwoFactorCompleted={SLACompleted}
          moduleName={level4customerSignModuleName}
          currentRole={currentRole}
        />
      ) : null}
    </Grid>
  );
}
